import React, { useState } from 'react';
import { arrayOf, number } from 'prop-types';

import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';

import ListingCard from '../../components/ListingCard/ListingCard';
import ListingImageFullScreenGallery from '../ListingPage/ListingImageFullScreenGallery/ListingImageFullScreenGallery';
import SearchBarForm from '../../extensions/StoreListingPage/components/SearchBarForm/SearchBarForm';
import ProductSkeleton from '../../extensions/StoreListingPage/components/ProductSkeleton/ProductSkeleton';

import css from './StoreListingPage.module.css';

const NUMBER_OF_SKELETONS = 4;

const SectionListings = props => {
  const [largePicModalOptions, setLargePicModalOptions] = useState({
    isModalOpen: false,
    imageItems: [],
  });

  const {
    intl,
    listingNumber,
    listings,
    searchKeywords,
    searchListingsInProgress,
    searchListingsError,
    onSearchProducts,
  } = props;

  const openLargePicModal = imageItems => {
    setLargePicModalOptions({
      isModalOpen: true,
      imageItems,
    });
  };

  const handleSearch = values => {
    const { searchKeywords } = values;
    onSearchProducts(searchKeywords);
  }

  const listingHeaderSection = (
    <div className={css.sectionListingsWrapper}>
      <h2 className={css.sectionListingsTitle}>
        {intl.formatMessage(
          { id: 'StoreListingPage.SectionListings.title' },
          { listingNumber }
        )}
      </h2>
      <SearchBarForm
        intl={intl}
        onSubmit={handleSearch}
        queryInProgress={searchListingsInProgress}
      />
    </div>
  );

  if (searchListingsInProgress) {
    return (
      <div className={css.sectionListingsContainer}>
        {listingHeaderSection}
        <div
          className={`${css.listingsContainer} categoryview list shortername`}
        >
          {Array.from(new Array(NUMBER_OF_SKELETONS)).map((_, index) => (
            <ProductSkeleton key={index} />
          ))}
        </div>
      </div>
    );
  }

  if (searchListingsError) {
    return (
      <div className={css.sectionListingsContainer}>
        {listingHeaderSection}
        <p className={css.error}>
          {intl.formatMessage({ id: 'StoreListingPage.searchError' })}
        </p>
      </div>
    );
  }

  if (listings.length === 0) {
    return (
      <div className={css.sectionListingsContainer}>
        {listingHeaderSection}
        <p className={css.noResults}>
          {!!searchKeywords
            ? intl.formatMessage(
                { id: 'StoreListingPage.noSearchResults' },
                {
                  searchKeywords: (
                    <span className={css.keywordsHightlight}>
                      {searchKeywords}
                    </span>
                  ),
                }
              )
            : intl.formatMessage({ id: 'StoreListingPage.noResults' })}
        </p>
      </div>
    );
  }

  return (
    <div className={css.sectionListingsContainer}>
      {listingHeaderSection}
      <div className={`${css.listingsContainer} categoryview list shortername`}>
        {listings.map(l => (
          <ListingCard
            key={l.id.uuid}
            className={`${css.listingCard} box`}
            listing={l}
            showZoomIcon={true}
            openLargePicModal={openLargePicModal}
          />
        ))}
      </div>
      <ListingImageFullScreenGallery
        imageItems={largePicModalOptions.imageItems}
        isModalOpen={largePicModalOptions.isModalOpen}
        closeModal={() =>
          setLargePicModalOptions({
            isModalOpen: false,
            imageItems: [],
          })
        }
      />
    </div>
  );
};

SectionListings.defaultProps = {
  listings: [],
};

SectionListings.propTypes = {
  intl: intlShape.isRequired,
  listingNumber: number,
  listings: arrayOf(propTypes.listing),
};

export default injectIntl(SectionListings);
