import React from 'react'
import classNames from 'classnames';
import { bool, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputBase } from '@mui/material';
import { Form } from '../../../../components';
import { intlShape } from '../../../../util/reactIntl';

import css from './SearchBarForm.module.css';

const SearchBarForm = props => {

  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          intl,
          form: formApi,
          rootClassName,
          className,
          handleSubmit,
          queryInProgress,
        } = formRenderProps;
        const classes = classNames(rootClassName || css.root, className);

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <InputBase
              className={css.searchInput}
              onChange={e => {
                formApi.change('searchKeywords', e.target.value);
              }}
              placeholder={intl.formatMessage({
                id: 'SearchBarForm.placeholder',
              })}
            />

            <IconButton
              type="submit"
              disabled={queryInProgress}
            >
              <SearchIcon />
            </IconButton>
          </Form>
        );
      }}
    />
  );
};

SearchBarForm.defaultProps = {
  className: '',
  rootClassName: '',
  queryInProgress: false,
};

SearchBarForm.propTypes = {
  className: string,
  rootClassName: string,
  queryInProgress: bool,
  intl: intlShape.isRequired,
};

export default SearchBarForm;
