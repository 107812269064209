import React from "react";
import classNames from "classnames";
import { Skeleton } from "@mui/material";

import css from "./ProductSkeleton.module.css";

const ProductSkeleton = props => {
  const {
    rootClassName,
    className,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <Skeleton animation="wave" variant="rounded" width="100%" sx={{ marginBottom: '0.5rem' }}>
        <div className={css.imageSkeleton} />
      </Skeleton>
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} width="80%" />
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} width="40%" />
    </div>
  );
}

export default ProductSkeleton;